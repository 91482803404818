@import "@/assets/scss/style.scss";

.wrapper {
    padding: 24px 24px 40px 24px;
    border-radius: 10px;
    border: 2px solid #eee7ff;
    width: 80vw;
    height: 100%;
    margin: 36px auto;
    background-color: $white-two;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }
}

::v-deep .product-version-column {
    white-space: nowrap;
    & .ant-table-column-title {
        font-weight: bold;
    }
}

::v-deep .ant-table {
    overflow-x: auto;
}

::v-deep .ProductMaterials {
    .ant-table-row {
        cursor: pointer;
    }
}
